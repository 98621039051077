<template>
  <div
    class="w-full grid grid-cols-1 sm:grid-cols-2 gap-y-2 gap-x-2 lg:gap-y-0 lg:gap-x-4 lg:grid-cols-4 lg:flex lg:justify-between">
    <!-- Conversations -->
    <div
      class="w-full px-4 lg:px-5 py-3 lg:py-8 border rounded-lg flex items-center shadow">
      <div class="h-12 space-x-4 flex">
        <div
          class="w-12 h-full flex justify-center items-center bg-blue-100 rounded">
          <svg
            class="w-5 h-5"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.5607 0.255492C11.0603 0.412956 11.4 0.876213 11.4 1.39998V7.39998L16.2 7.39998C16.6474 7.39998 17.0577 7.64893 17.2644 8.04581C17.471 8.44268 17.4397 8.92157 17.1831 9.28813L8.78306 21.2881C8.4827 21.7172 7.93875 21.9019 7.43921 21.7445C6.93968 21.587 6.59998 21.1237 6.59998 20.6L6.59998 14.6H1.79998C1.35253 14.6 0.942242 14.351 0.735605 13.9542C0.528967 13.5573 0.560305 13.0784 0.816901 12.7118L9.2169 0.711822C9.51726 0.282738 10.0612 0.0980275 10.5607 0.255492Z"
              fill="#0074E0" />
          </svg>
        </div>
        <div class="text-grey-500 flex flex-col justify-between">
          <p class="text-xs flex-none">Conversations</p>
          <h1 class="text-2xl">{{ profileCardsData.conversationCount }}</h1>
        </div>
      </div>
    </div>

    <!-- Messages Sent -->
    <div
      class="w-full px-4 lg:px-5 py-3 lg:py-8 border rounded-lg flex items-center shadow">
      <div class="h-12 space-x-4 flex">
        <div
          class="w-12 h-full flex justify-center items-center bg-green-100 rounded">
          <svg
            class="w-5 h-5"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.6 12.6V2.99998C19.6 1.67449 18.5255 0.599976 17.2 0.599976H2.80002C1.47454 0.599976 0.400024 1.67449 0.400024 2.99998V12.6C0.400024 13.9255 1.47454 15 2.80002 15H6.40002L10 18.6L13.6 15H17.2C18.5255 15 19.6 13.9255 19.6 12.6ZM4.00002 5.39998C4.00002 4.73723 4.53728 4.19998 5.20002 4.19998H14.8C15.4628 4.19998 16 4.73723 16 5.39998C16 6.06272 15.4628 6.59998 14.8 6.59998H5.20002C4.53728 6.59998 4.00002 6.06272 4.00002 5.39998ZM5.20002 8.99998C4.53728 8.99998 4.00002 9.53723 4.00002 10.2C4.00002 10.8627 4.53728 11.4 5.20002 11.4H8.80002C9.46277 11.4 10 10.8627 10 10.2C10 9.53723 9.46277 8.99998 8.80002 8.99998H5.20002Z"
              fill="#00C98D" />
          </svg>
        </div>
        <div class="text-grey-500 flex flex-col justify-between">
          <p class="text-xs flex-none">Messages Sent</p>
          <h1 class="text-2xl">{{ profileCardsData.messagesSentCount }}</h1>
        </div>
      </div>
    </div>

    <!-- Outreach Requests -->
    <div
      class="w-full px-4 lg:px-5 py-3 lg:py-8 border rounded-lg flex items-center shadow">
      <div class="h-12 space-x-4 flex">
        <div
          class="w-12 h-full flex justify-center items-center bg-blue-100 rounded">
          <svg
            class="w-6 h-6"
            viewBox="0 0 28 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              opacity="0.32"
              d="M8.16663 14.1426H9.54352C10.0997 14.1426 10.5785 14.5354 10.6875 15.0811L11.6666 19.9799L6.48044 23.4396C5.70513 23.9569 4.66663 23.4008 4.66663 22.4683V17.645C4.66663 15.7106 6.23363 14.1426 8.16663 14.1426Z"
              fill="#0074E0" />
            <path
              opacity="0.32"
              d="M19.8334 14.1426H18.4565C17.9003 14.1426 17.4215 14.5354 17.3124 15.0811L16.3334 19.9799L21.5196 23.4396C22.2949 23.9569 23.3334 23.4008 23.3334 22.4683V17.645C23.3334 15.7106 21.7664 14.1426 19.8334 14.1426Z"
              fill="#0074E0" />
            <path
              opacity="0.32"
              d="M15.3604 23.4822H12.6397C12.0871 23.4822 11.6056 23.8768 11.6519 24.4279C11.7248 25.2958 12.134 26.5089 13.7716 26.9324C13.9207 26.971 14.0794 26.971 14.2285 26.9324C15.8661 26.5089 16.2753 25.2958 16.3482 24.4279C16.3945 23.8768 15.9129 23.4822 15.3604 23.4822Z"
              fill="#0074E0" />
            <path
              d="M8.16661 9.47273C9.04143 6.84673 11.8838 3.89256 13.2513 2.57859C13.673 2.17351 14.327 2.17351 14.7485 2.57859C16.1162 3.89256 18.9586 6.84672 19.8332 9.47273C20.7354 12.181 19.6358 17.0458 18.8167 19.9476C18.4128 21.3785 17.0889 22.3148 15.6031 22.3148H12.3867C10.9058 22.3148 9.58507 21.3845 9.1791 19.9593C8.35595 17.0696 7.25218 12.2175 8.16661 9.47273Z"
              fill="#0074E0" />
            <path
              d="M14 14.1425C15.2886 14.1425 16.3333 13.0972 16.3333 11.8076C16.3333 10.5181 15.2886 9.47272 14 9.47272C12.7113 9.47272 11.6666 10.5181 11.6666 11.8076C11.6666 13.0972 12.7113 14.1425 14 14.1425Z"
              fill="white" />
          </svg>
        </div>
        <div class="text-grey-500 flex flex-col justify-between">
          <p class="text-xs flex-none">Outreach Requests</p>
          <h1 class="text-2xl">{{ profileCardsData.outreachRequestsCount }}</h1>
        </div>
      </div>
    </div>

    <!-- Profile Views -->
    <!-- <div class="w-full px-4 lg:px-5 py-3 lg:py-8 border rounded-lg flex items-center shadow">
                <div class="h-12 space-x-4 flex">
                    <div class="w-12 h-full flex justify-center items-center bg-pink-100 rounded">
                        <svg class="w-6 h-6" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.32" fill-rule="evenodd" clip-rule="evenodd" d="M14 22.3148C20.0841 22.3148 23.5782 17.7789 24.9645 15.4657C25.4572 14.6436 25.4572 13.6415 24.9645 12.8193C23.5782 10.5062 20.0841 5.97034 14 5.97034C7.91577 5.97034 4.42171 10.5062 3.03538 12.8193C2.54273 13.6415 2.54273 14.6436 3.03538 15.4657C4.42171 17.7789 7.91577 22.3148 14 22.3148Z" fill="#E742A1"/>
                            <path d="M14 17.6449C15.933 17.6449 17.5 16.0769 17.5 14.1426C17.5 12.2083 15.933 10.6402 14 10.6402C12.067 10.6402 10.5 12.2083 10.5 14.1426C10.5 16.0769 12.067 17.6449 14 17.6449Z" fill="#E742A1"/>
                        </svg>
                    </div>
                    <div class="text-grey-500 flex flex-col justify-between">
                        <p class="text-xs flex-none">Profile Views</p>
                        <h1 class="text-2xl">{{ profileCardsData.profileViewsCount }}</h1>
                    </div>
                </div>
            </div> -->
  </div>
</template>

<script>
  export default {
    props: ["data"],
    data() {
      return {
        profileCardsData: {},
      };
    },
    created() {
      this.profileCardsData = this.data;
    },
  };
</script>
